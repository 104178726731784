<template>
  <div class="page-scan">
    <!--返回-->
    <van-nav-bar title="扫描二维码" fixed @click-left="clickIndexLeft()" class="scan-index-bar">
      <template #left>
        <van-icon name="arrow-left" size="18" color="#fff"/>
        <span style="color: #fff"> 取消 </span>
      </template>
    </van-nav-bar>

    <!-- 扫码区域 -->
<!--    <qrcode-stream  @decode="onDecode" @init="onInit"/>-->
    <!-- 提示语 -->
    <p class="error">{{ error }}</p>
    <p class="decode-result" v-if="this.error!=''">Last result: <b>{{ result }}</b></p>
  </div>
</template>
<script>
//引用组件
// import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  //注册组件
  // components: { QrcodeStream },
  data () {
    return {
      result: '',
      error: '',
      id:''
    }
  },
  mounted(){
    //接收上个页面传过来的值
    this.id = this.$route.query.userId
    console.log(this.id)
  },
  methods: {
    // decode方法，当识别到二维码会把识别信息触发出来
    onDecode (result) {
      this.result = result
    },
    // init方法是初始化调用摄像头,此时如果摄像头报错会有很多提示，请酌情处理
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = `ERROR: Camera access is only permitted in secure context.
            Use HTTPS or localhost rather than HTTP.`;
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    clickIndexLeft(){  // 返回上一页
      this.$router.back();
    }
  }
}
</script>
<style scoped>
.error {
  font-weight: bold;
  color: red;
}
.scan-index-bar{
  background-image: linear-gradient( -45deg, #42a5ff ,#59cfff);
}
.van-nav-bar__title{
  color: #fff !important;
}
.scan-video{
  height: 80vh;
}
.scan-tip{
  width: 100vw;
  text-align: center;
  margin-bottom: 10vh;
  color: white;
  font-size: 5vw;
}
/* .page-scan{
  overflow-y: hidden;
  background-color: #363636;
} */
</style>

