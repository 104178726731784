import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaseNavBar from "./components/BaseNavBar.vue";
import BaseTopTabs from "./components/BaseTopTabs.vue";
import 'vant/lib/index.css';
import './config/vant/vant.js'
import './config/css/global.css'
import JhRefreshView from "./components/JhRefreshView/index.vue";
import BaseRefreshView from "./components/BaseRefreshView.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {
  clickMusic,
  clickStoreMusic,
  clickShoesTypeMusic,
  shoseErrorMusic,
  shosePaymentMusic,
  openReturnMusic,
  shoePhoto
} from './utils/music'


Vue.component('BaseNavBar', BaseNavBar)
Vue.component('BaseTopTabs', BaseTopTabs)
Vue.component('JhRefreshView', JhRefreshView)
Vue.component('BaseRefreshView', BaseRefreshView)
Vue.use(ElementUI);



Vue.config.productionTip = false
Vue.prototype.clickMusic = clickMusic
Vue.prototype.clickStoreMusic = clickStoreMusic
Vue.prototype.clickShoesTypeMusic = clickShoesTypeMusic
Vue.prototype.shoseErrorMusic = shoseErrorMusic
Vue.prototype.shosePaymentMusic = shosePaymentMusic
Vue.prototype.openReturnMusic = openReturnMusic
Vue.prototype.shoePhoto = shoePhoto


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

