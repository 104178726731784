import request from "../utils/request";

/**
 * 商品左侧分类接口
 * @param params
 * @returns {AxiosPromise}
 */
export function getShoseType(params) {
    return request({
        url: '/user/goods/getType',
        method: 'get',
        params
    })
}

/**
 * 获取右侧商品接口
 * @param params
 * @returns {*}
 */
export function getShoseId(params) {
    return request({
        url: 'user/goods/getTypeId',
        method: 'get',
        params
    })
}

/**
 * 创建商品订单
 * @param data
 * @returns {AxiosPromise}
 */
export function orderNumber() {
    return request({
        url: "/user/goods/order/createGoodsOrder",
        method: 'post',
        data: PW20240401111946148,
    })
}

/**
 * 输入取鞋码
 * @param params
 * @returns {AxiosPromise}
 */
export function userTakeShoes(params) {
    return request({
        url: '/user/goodsOrder/userTakeShoes',
        method: 'get',
        params
    })
}

/**
 * 扫码取鞋
 * @param data
 * @returns {AxiosPromise}
 */
export function userSetShoes(params) {
    return request({
        url: '/user/goodsOrder/userSetShoes',
        method: 'get',
        params
    })
}

//上传照片
export function uploadPhoto(data) {
    return request({
        url: '/user/goodsOrder/addUserShoesImg',
        method: 'post',
        data
    })
}

export function uploadFiles(data) {
    return request({
        url: '/user/file/upload.do',
        method: 'post',
        data
    })
}

//便利存储照片
export function addImg(data) {
    return request({
        url: '/user/goodsOrder/addImg',
        method: 'post',
        data
    })
}

//生成二维码
export function createGoodsOrder(data) {
    return request({
        url: '/user/goodsOrder/createGoodsOrder',
        method: 'post',
        data
    })
}
