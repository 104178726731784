<template>
  <div	class="home">
  <div class="top" >
    <div class="left" v-loading="loading"><h1>摄像头1</h1>
      <video ref="video1" width="240" height="180" autoplay style="background-color: black;"></video>
    </div>
    <div class="right" v-loading="loading"><h1>摄像头2</h1>
      <video ref="video2" width="240" height="180" autoplay style="background-color: black;"></video>
    </div>
    <div class="right" v-loading="loading"><h1>摄像头3</h1>
      <video ref="video3" width="240" height="180" autoplay style="background-color: black;"></video>
    </div>
    <div class="right" v-loading="loading"><h1>摄像头4</h1>
      <video ref="video4" width="240" height="180" autoplay style="background-color: black;"></video>
    </div>
  </div>
    <div class="bottom"><div class="left"><h1>照片</h1>
      <!--canvas截取流-->
      <canvas ref="canvas1" width="240" height="180" style="background-color: black;"></canvas>
    </div>
    <div class="right">
      <h1>照片</h1>
      <!--canvas截取流-->
      <canvas ref="canvas2" width="240" height="180" style="background-color: black;"></canvas>
    </div>
    <div class="bottom">
      <div class="left"><h1>照片</h1>
      <!--canvas截取流-->
      <canvas ref="canvas3" width="240" height="180" style="background-color: black;"></canvas>
    </div>
      <div class="right">
        <h1>照片</h1>
        <!--canvas截取流-->
        <canvas ref="canvas4" width="240" height="180" style="background-color: black;"></canvas>
      </div>
  </div>

  </div>
    <div class="btns">
      <el-button type="primary" @click="clickBack" style="margin-right:10px">返回</el-button>
      <!--关闭摄像头-->
      <el-button type="primary" @click="closeCamera" style="margin-right:10px">关闭摄像头</el-button>
      <el-button type="primary" @click="savePhoto" style="margin-right:10px">发送</el-button>
    </div>
  </div>
</template>

<script>
import {uploadPhoto,uploadFiles} from "../../api/shop";
import ws from 'nodejs-websocket'

export default {
  data() {
    return {
      loading: false,
      devicesIds: [],//摄像头数组
      imgList:[],
      flag: true,
    };
  },
  created() {

  },
  mounted() {
    this.startCamera()
  },
  methods: {

    // 获取摄像头权限
    startCamera() {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
              this.init()
            })
            .catch(error => {
            });
      } else {
        alert('Your browser does not support camera access');
      }
    },
    // 初始化获取摄像头
    async init() {
      try {
        let devices = await navigator.mediaDevices.enumerateDevices();
        console.log(devices, "设备");
        devices.forEach((device) => {
          if (device.kind == "videoinput") {
            this.devicesIds.push(device.deviceId);
            this.callCamera()
          }
        });
        console.log(this.devicesIds, "摄像头");
      } catch (error) {
      }
    },
    // 返回
    clickBack() {
      this.$router.back()
    },
    //调用摄像头
    callCamera() {
      this.loading = true
      console.log("方法调用了",this.devicesIds)
      this.devicesIds.forEach((item, index) => {
        // H5调用电脑摄像头API
        console.log(item,index)
        navigator.mediaDevices
            .getUserMedia({
              video: {
                deviceId: {exact: item},
              },
            })
            .then((success) => {
              console.log(success,'流')
              console.log('3333',this.$refs[`video${index + 1}`].src0bject)
              // 摄像头开启成功
              this.$refs[`video${index + 1}`].srcObject = success;
              // 实时拍照效果
              this.$refs[`video${index + 1}`].play();
              this.loading = false
            })
            .catch((error) => {
              console.error("摄像头开启失败，请检查摄像头是否可用!",error);
            });
      })

    },
    //拍照
    photograph() {
      this.imgList =[]
      this.flag = false;

      this.devicesIds.forEach(async (item, index) => {
        let ctx = this.$refs[`canvas${index + 1}`].getContext("2d");
        // 把当前视频帧内容渲染到canvas上
        ctx.drawImage(this.$refs[`video${index + 1}`], 0, 0, 240, 180);
        // 转base64格式、图片格式转换、图片质量压缩---支持两种格式image/jpeg+image/png
        let imgBase64 = this.$refs[`canvas${index + 1}`].toDataURL("image/jpeg", 1);
        console.log("imgBase64",imgBase64)

        //将数据传到服务器保存
        const result = await uploadFiles({item})
        console.log(result)
        this.imgList.push(result);
        /**------------到这里为止，就拿到了base64位置的地址，后面是下载功能----------*/
      })

      console.log('0000',this.imgList)
      this.savePhoto()
    },
    //保存图片
    savePhoto() {
      // if(this.imgList.length == 0) {
      //   return
      // }
      const message = 'Hello, server!';
      this.socket.send(message);

    },

    closeCamera(){
      console.log(this.$refs["video"])
      if (!this.$refs["video"].src0bject) return;
      let stream = this.$refs["video"].src0bject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs["video"].src0bject = null;
    }
  },
};
</script>

<style scoped>
.home{
  width: 100%;
  background-color: #ccc;
}
.top,.bottom{
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}
.btns{
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
