<template>
  <div>
    <div class="nav-title">
      <div class="arrow" @click="clickReturn">返回</div>
      <p>请输入取鞋码</p>
      <div class="arrow">{{ countdown }}s</div>
    </div>
    <div class="content">
      <div class="tips">
        <p><span class="num">46</span>剩余</p>
        <img src="../../assets/img/bigproject_res_main_xiezi2.png" alt="">
      </div>
      <div class="btn">
        <button class="return" @click="clickReturn">取消存放</button>
        <button class="order" @click="clickCreateOrder">开箱存放</button>
      </div>
    </div>

    <!--  二维码弹窗  -->
    <van-popup v-model="show" :style="{ width:'40%' }" >
      <div class="popup">
        <p>请使用微信扫码支付订单</p>
        <p>请在两分钟内完成订单支付</p>
        <img class="qrcode" :src="orderNumber" alt="">
        <div class="btn" @click="clickClosePopup">取消</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {orderNumber} from "../../api/shop";
import {shosePaymentMusic} from "../../utils/music";
export default {
  name: "orderNumber",
  data(){
    return{
      countdown:120,
      order:'', // 订单数据,
      orderNumber: '', // 订单二维码
      show: false
    }
  },
  created() {
    this.order = this.$route.query.order
    this.timeFn()
  },
  methods:{
    // 创建订单
    clickCreateOrder(){
      this.clickMusic()
      let data = JSON.parse(decodeURIComponent(this.order))
      orderNumber(data).then(res => {
        console.log(res)
        this.orderNumber = 'data:image/jpg;base64,'+ res.data
        this.show = true
        shosePaymentMusic()
      })
    },
    // 关闭弹窗
    clickClosePopup(){
      this.show = false
    },
    // 返回
    clickReturn(){
      this.show = false
      this.$router.back()
    },
    // 倒计时
    timeFn() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.$router.back()
        }
      }, 1000);
    },
  }
}
</script>

<style scoped>
.nav-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}
.nav-title .arrow{
  padding: 10px 20px;
  box-shadow: 0 2px 4px 4px #e2e2e2;
  border-radius: 5px;
}

.content{
  width: 80%;
  /*background: red;*/
  margin: 30px auto;
}

.tips{
  background: #f7c777;
  width: 100%;
  height: 350px;
  box-shadow: 0 4px 4px #eeeeee;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tips p{
  font-size: 32px;
  color: #333333;
}
.tips .num{
  font-size: 50px;
  color: white;
  margin-right: 10px;
}

.btn{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn button{
  border: none;
  color: #ffffff;
  font-size: 28px;
  border-radius: 10px;
  width: 300px;
  padding: 10px 0;
  margin-top: 50px;
}
.btn .return{
  background: #0f77d2;
}

.btn .order{
  background: #f7c777;
}
/* 弹窗 */
.popup{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
.popup p:nth-child(1){
  font-size: 38px;
  font-weight: 700;
  color: #333333;
}
.popup p:nth-child(2){
  font-size: 28px;
  color: #333333;
  margin-top: -10px;
}
.popup .qrcode{
  width: 400px;
  height: 400px;
  margin: 50px 0 30px;
}
.popup .btn{
  border: none;
  color: #ffffff;
  font-size: 28px;
  border-radius: 10px;
  width: 300px;
  padding: 10px 0;
  background: #f7c777;
}
</style>
